.faq {
    padding-top: 0 !important;
}

.layer__faq [class^="col-"] {
    margin: auto;
}

.layer__faq [class^="section__title"] {
    text-align: center;
}

.accordion {
    margin-bottom: 0;

    .card {
        color: $body-font-color;
        margin-bottom: 15px;
        border-radius: $block-radius;
        border: 1px solid $secondarycolor !important;

        &:last-of-type,
        &:not(:first-of-type):not(:last-of-type),
        &:first-child {
            border-radius: $block-radius;
        }
    }
}

.archive__content--faq {
    .text--centered {
        h1 {
            margin-bottom: 10px;
        }

        p:last-of-type {
            margin-bottom: 37px;
        }
    }
}

.card-header {
    border-bottom: none;
    padding: 0;

    a {
        color: $body-font-color;
        background: $white;
        padding: 17px 17px 17px 40px;
        display: block;
        font-weight: 700;
        font-size: 16px;

        &::before {
            content: "";
            float: right;
            width: 20px;
            height: 20px;
            background: url(../../svg/angle-up-solid.svg) no-repeat center;
            background-size: 12px 18px;
            margin: 5px 3px 5px 15px;
            transition: all 0.3s;
        }

        &.collapsed::before {
            transform: rotate(-180deg);
        }
    }
}

.card-body {
    padding: 0 70px 22px 40px;

    p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
    }

    ul {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        margin-top: 10px;
        margin-left: 0;
        padding: 0 0 0 17px;
    }
}

.search-filter-reset {
    @extend .btn;
    @extend .btn--primary;

    margin-top: 18px;
}

.single__sidebar--faq {
    .sidebar__title {
        color: #000;
    }
}

.sidebar__content {
    .searchandfilter {
        > ul {
            margin: 0;
            padding: 0;

            > li {
                margin: 0;
                padding: 0;

                > ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;

                    > li {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0;
                        margin-bottom: 7px;

                        &.sf-level-0 {
                            position: relative;

                            input[type=checkbox]:checked + label {
                                &::after {
                                    opacity: 1;
                                    transition: all 100ms ease-in-out;
                                }
                            }

                            .sf-input-checkbox {
                                display: none;
                            }

                            label {
                                background-repeat: no-repeat;
                                background-position: 65px center;
                                padding: 0 25px 0 35px !important;
                                cursor: pointer;
                                width: 100%;

                                &::before {
                                    content: '';
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid #007672;
                                    background-color: #fff;
                                    border-radius: 5px;
                                    position: absolute;
                                    left: 0;
                                    top: 5px;
                                }

                                &::after {
                                    content: '';
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    left: 0;
                                    top: 5px;
                                    background-image: url('../../svg/check-solid-green.svg');
                                    background-size: 12px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    opacity: 0;
                                    transition: all 100ms ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .archive__content--faq {
        padding-bottom: 0;
    }

    .single__sidebar--faq {
        margin-top: 0;
    }

    .sidebar__content .searchandfilter > ul > li > ul > li.sf-level-0 label::before {
        top: 4px;
    }

    .sidebar__content .searchandfilter > ul > li > ul > li.sf-level-0 label::after {
        top: 4px;
    }
}

@media screen and (max-width: 767px) {
    .sidebar__content .searchandfilter > ul > li > ul > li.sf-level-0 label {
        padding: 0 25px 0 27px !important;
    }

    .sidebar__content .searchandfilter > ul > li > ul > li.sf-level-0 label::before {
        width: 17px;
        height: 17px;
    }

    .sidebar__content .searchandfilter > ul > li > ul > li.sf-level-0 label::after {
        width: 17px;
        height: 17px;
        background-size: 10px;
    }

    .search-filter-reset {
        margin-top: 12px;
    }

    .card-header a {
        padding-left: 31px;
        font-size: 14px;
    }

    .card-body {
        padding: 0 31px 25px;

        p {
            font-size: 15px;
        }
    }

    .accordion {
        margin-top: 15px;
    }

    .archive__content--faq {
        .text--centered {
            p:last-of-type {
                margin-bottom: 10px;
            }
        }
    }
}
