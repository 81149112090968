//@font-face {
//    font-family: 'geomanist', sans-serif;
//    src: url('../../fonts/geomanist-light-webfont.woff2') format('woff2'), url('geomanist-light-webfont.woff') format('woff'), url('../../fonts/geomanist-regular-webfont.woff2') format('woff2'), url('geomanist-regular-webfont.woff') format('woff'), url('../../fonts/geomanist-medium-webfont.woff2') format('woff2'), url('geomanist-medium-webfont.woff') format('woff'), url('../../fonts/geomanist-bold-webfont.woff2') format('woff2'), url('geomanist-bold-webfont.woff') format('woff'), url('../../fonts/geomanist-black-webfont.woff2') format('woff2'), url('geomanist-black-webfont.woff') format('woff');
//}

@font-face {
    font-family: 'geomanist';
    src: url('../../fonts/geomanist-light-webfont.woff2') format('woff2'), url('../../fonts/geomanist-light-webfont.woff') format('woff');
    src: local('☺'), url('../../fonts/geomanist-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'geomanis';
    src: url('../../fonts/geomanist-regular-webfont.woff2') format('woff2'), url('../../fonts/geomanist-regular-webfont.woff') format('woff');
    src: local('☺'), url('../../fonts/geomanist-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'geomanist';
    src: url('../../fonts/geomanist-medium-webfont.woff2') format('woff2'), url('../../fonts/geomanist-medium-webfont.woff') format('woff');
    src: local('☺'), url('../../fonts/geomanist-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'geomanist';
    src: url('../../fonts/geomanist-bold-webfont.woff2') format('woff2'), url('../../fonts/geomanist-bold-webfont.woff') format('woff');
    src: local('☺'), url('../../fonts/geomanist-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'geomanist';
    src: url('../../fonts/geomanist-black-webfont.woff2') format('woff2'), url('../../fonts/geomanist-black-webfont.woff') format('woff');
    src: local('☺'), url('../../fonts/geomanist-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'geomanistbook', sans-serif;
    src: url('../../fonts/geomanist-book-webfont.woff2') format('woff2'), url('../../fonts/geomanist-book-webfont.woff') format('woff');
}

body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
}

.main {
    overflow-x: hidden;
    padding: 0;
}

.layer {
    padding: 80px 0;
}

.clients {
    padding: 98px 0 88px;
}

.svg-inline--fa {
    width: 30px;
    height: auto;
}

.cookie__inner__title {
    color: #5cb8b2 !important;
}

.cookie__inner__text a,
.cookie__toggle {
    text-decoration: underline !important;
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primarycolor;
    }
}

.cookie__inner__btn {
    border-color: $primarycolor !important;
    background: $primarycolor !important;
}

.cookie__inner__btn--choice {
    border-color: $primarycolor !important;

    &:hover {
        color: #fff !important;
        background: $primarycolor !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    word-break: break-word;
}

img {
    max-width: 100%;
}

b,
strong {
    font-family: $font-primary;
    font-weight: 600;
}

h1,
.sidebar__title,
.layer-block h3,
.onzecoating .section__title h2,
.advies__content .section__title h3,
.projecten__title,
.clients__title {
    margin-bottom: 45px;
    font-family: $font-primary;
    font-size: 49px;
    font-weight: 900;
    line-height: 1.2;
    text-transform: uppercase;
    color: $secondarycolor;

    b,
    strong {
        display: block;
        font-size: 38px;
        font-weight: 900;
        color: #000;
    }
}

.clients__title {
    margin-bottom: 0;
    font-size: 42px;
    line-height: 1;

    strong {
        margin-top: 45px;
        font-size: 17px;
        background: -webkit-linear-gradient(135deg, #000 50%, #000 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.projecten__title {
    color: #000;
}

.onzecoating .section__title h2 {
    line-height: 1;

    strong {
        font-size: 49px;
    }
}

.advies__content .section__title h3 {
    margin-bottom: 40px;
    line-height: 1;

    strong {
        font-size: 17px;
    }
}

.layer-block h3 {
    font-size: 38px;
}

.single__main {
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 14px;
        text-transform: uppercase;
    }
}

.single__main--page {
    padding-right: 80px;
}

.branche__content,
.coating__content,
.content__service,
.oplossing__content,
.projecten__content {
    padding-bottom: 40px;
}

.sidebar__title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;

    b,
    strong {
        font-size: 30px;
        font-weight: 700;
    }
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.archive__content {
    padding: 105px 0 40px;

    h1 {
        strong,
        b {
            margin-top: -10px;
        }
    }
}

#offerte-popup {
    max-width: 740px;
    padding: 70px 85px 65px;
    border-radius: 50px;

    .fancybox-button {
        top: 40px;
        right: 40px;
    }
}

.archive__list {
    padding: 0 0 60px;
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -20px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 25px;
}

.slider__top__next {
    right: 25px;
}

.slick-slide {
    cursor: pointer;

    &.slick-current {
        cursor: default;
    }

    a {
        &:focus {
            outline: none !important;
        }
    }

    &:focus {
        outline: none !important;
    }

    img {
        border-radius: $border-radius;
        outline: none !important;

        &:focus {
            outline: none !important;
        }
    }
}

.slider__nav {
    margin: 20px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 12px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.block {
    display: flex;
    flex-direction: column;
    overflow: visible;
    height: 100%;
    padding-bottom: 70px;
    border-radius: $block-radius;

    &:hover {
        img {
            transform: scale(1.05);

            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance:none) {
                    transform: none;
                }
            }
        }

        .btn {
            background-color: lighten($primarycolor, 5) !important;
        }
    }
}

.block__img {
    position: relative;

    &::before {
        position: absolute;
        z-index: 2;
        top: -9px;
        left: -5px;
        display: inline-block;
        width: calc(100% + 10px);
        height: calc(100% + 18px);
        content: "";
        background: url(../../svg/projects-lines.svg) no-repeat;
        background-size: cover;
    }
}

.block__img__inner {
    display: block;
    overflow: hidden;
    width: 100%;
    border-radius: 40px;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 0.3s ease;
    }
}

.block__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    padding: 40px 20px 0;
    color: $body-font-color;

    .btn {
        position: relative;
        display: inline-block;
        margin-top: auto;
        transition: all 0.3s;
    }
}

.block__title {
    display: block;
    font-family: $font-primary;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.3;
    text-transform: uppercase;
    color: $secondarycolor;
}

.block__subtitle {
    line-height: 1.3;
    margin-top: -2px;
    margin-bottom: 23px;
    font-family: $font-primary;
    font-size: 17px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000;
}

.block__links {
    margin: 0 0 25px;
    padding: 0;
    list-style: none;
    font-size: 17px;

    li {
        padding: 0 0 5px 32px;
        line-height: 1.4;
        text-align: left;
        background: url(../../svg/list-bg.svg) no-repeat left center;
        background-size: 15px;

        &,
        a {
            &:hover {
                color: $black;
            }
        }
    }
}

.front__intro {
    padding-top: 110px;

    h1 {
        strong {
            font-size: 49px;
        }
    }
}

.layer-block,
.onzecoating {
    .block__title {
        font-size: 32px;
        line-height: 1.2;
        text-align: center;
    }

    .block__summary {
        margin-bottom: 32px;
    }

    .block__links {
        li {
            padding: 0 0 11px 50px;
            background-position: 18px 6px;

            &,
            a {
                &:hover {
                    color: $primarycolor;
                }
            }
        }
    }

    .btn {
        padding: 13px 58px;
    }
}

.block__phone,
.block__email,
.block__mobile {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 34px;
    word-break: break-all;

    svg {
        position: absolute;
        top: 8px;
        left: 0;
        width: 16px;
        height: 16px;
        transition: all 0.3s;
        color: $primarycolor;
        fill: $primarycolor;
    }

    &:hover {
        a {
            color: $secondarycolor;
        }

        svg {
            color: $secondarycolor;
            fill: $secondarycolor;
        }
    }
}

.block__phone {
    margin-bottom: 3px;
}

.block__no__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #f9f9f9;
}

.block__date {
    display: block;
    margin-top: 7px;
    font-size: 18px;
    font-weight: 300;
    color: $primarycolor;

    svg {
        width: 16px;
        height: 18px;
        margin: -8px 8px 0 0;
        color: $primarycolor;
    }
}

.block__summary {
    display: block;
    width: 100%;
    height: 100%;
    margin: 10px 0 25px;
    font-size: 16px;
    line-height: 1.8;
    color: $body-font-color;
}

.block__list {
    padding: 19px 0 0;
}

.block__item {
    position: relative;
    display: block;
    margin: 0 0 8px 20px;
    color: $body-font-color;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        top: 5px;
        left: -18px;
        width: 6px;
        height: 18px;
        content: "";
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
    }
}

.single__content {
    padding: 105px 0 80px;

    .btn--prev {
        margin-top: 30px;
    }
}

.accordion {
    margin: auto;
}

.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 19px;
    border: 1px solid $primarycolor;
    border-radius: 5px;
    background: $white;

    &::after {
        position: absolute;
        display: none;
        content: "";
    }

    &::before {
        position: absolute;
        content: "";
    }
}

.checkmarkcontainer {
    position: relative;
    display: flex;
    min-width: 20px;
    min-height: 20px;
    margin: 0;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    a {
        font-size: 13px;
        text-decoration: underline;
        color: #9a9a9a;
    }

    .checkmark {
        &::before,
        &::after {
            top: 1px;
            left: 6px;
            width: 6px;
            height: 11px;
            transform: rotate(45deg);
            border: solid #034b46;
            border-width: 0 2px 2px 0;
        }
    }
}

.checkmarkcontainer input:checked ~ .checkmark::after {
    display: block;
}

.checkmarkcontainer input ~ .checkmark::before {
    display: none;
}

.section__title--md {
    font-size: 39px;
}

.text--centered {
    text-align: center;

    p:last-of-type {
        margin-bottom: 50px;
    }
}

.text--centered__projecten p:last-of-type {
    margin-bottom: 0;
}

.section__title--sm {
    font-size: 24px;
}

.text__dark--sm {
    font-size: 17px;
}

.text__dark--md {
    font-size: 38px;
}

.section__viewall {
    display: inline-block;
    margin-top: 68px;
    font-size: 24px;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
        color: #000;
    }

    &--faq {
        margin-top: 38px;
    }
}

.home .section__viewall {
    margin-top: 0;
}

// Form
.col-form-label {
    margin-right: 30px;
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: uppercase;
}

select.form-control {
    height: 54px;
    padding: 0 70px 0 35px;
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    border: none;
    -webkit-appearance: none;

    &,
    &:hover,
    &:focus,
    &:active {
        color: $primarycolor;
        background: rgba($primarycolor, 0.1) url(../../svg/angle-down-regular.svg) no-repeat 90% center;
        background-size: 20px 20px;
        box-shadow: none;
    }
}

// Jumbotron
.jumbotron {
    margin: 85px 0 0;
    padding: 15px 0 5px 55px;
    border-left: 10px solid #def1f0;
    border-radius: 0;
    background: none;

    h3 {
        margin: 0 0 15px;
        text-transform: uppercase;
    }

    .lead {
        p {
            margin-bottom: 15px;
            font-size: 25px;
            line-height: 1.6;
        }
    }

    .btn {
        margin-top: 5px !important;
        margin-bottom: 10px;

        svg {
            width: 18px;
            height: 18px;
            color: #7afff6;
        }

        &:first-of-type {
            svg {
                color: #faab73;
            }
        }
    }
}

.project__detail h1 {
    margin-bottom: 7px;
}

.layer__value {
    position: relative;
    overflow: hidden;
    margin-top: 80px;
    padding: 144px 0 70px;
    background-color: #def1f0;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: inline-block;
        content: "";
        pointer-events: none;
        border-top: 25px solid #fff;
        border-right: 100vw solid transparent;
        border-left: 0 solid transparent;
    }

    .block__links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            padding: 0 40px 11px 40px;
            background-position-y: 12px;
            background-size: 18px;

            &:last-of-type {
                padding-right: 0;
            }
        }
    }

    .block__links__item {
        font-size: 30px;
    }

    h3 {
        margin-bottom: 30px;
        font-size: 46px;
        text-transform: uppercase;
    }
}

.section__title--gradient {
    background: -webkit-linear-gradient($secondarycolor, $primarycolor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bottom__content__none {
    padding-bottom: 0;
}

.team__list {
    .block {
        align-items: center;
    }

    .block__img {
        max-width: 75%;

        &::before {
            width: calc(100% + 5px) !important;
            height: calc(100% + 14px) !important;
        }
    }

    .block__content {
        padding-top: 32px;
    }

    .block__phone {
        margin-bottom: 0;
    }

    .block__phone svg,
    .block__email svg {
        top: 0;
        left: 0;
        width: 19px;
        height: 29px;
    }

    .block__mobile svg {
        top: 5px;
        left: 2px;
        width: 13px;
        height: 21px;
    }
}

.gform_wrapper {
    .hidden_label {
        height: 0;
        opacity: 0;
    }
}

.grecaptcha-badge {
    display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block {
        display: block;
        flex: none;
    }

    .block__content {
        display: block;
    }

    .projects {
        .block {
            .block__content {
                text-align: center;
            }

            .block__subtitle {
                display: block;
            }
        }
    }

    .onzecoating {
        .block {
            .block__content {
                text-align: center;
            }

            .block__subtitle {
                display: block;
            }
        }
    }

    .checkmarkcontainer .checkmark {
        &::before,
        &::after {
            top: 2px;
            left: 7px;
        }
    }

    .block__title,
    .block__summary {
        flex: none;
    }

    .social__icons {
        li {
            list-style-image: url(data:0);
        }
    }

    .header__social {
        .social__icons {
            li {
                position: relative;
                top: -31px;
            }
        }
    }
}

@media screen and (max-width: 1599px) {
    h1,
    .layer-block h3,
    .onzecoating .section__title h2,
    .advies__content .section__title h3,
    .projecten__title {
        font-size: 38px;

        b,
        strong {
            font-size: 27px;
        }
    }

    .advies__content .section__title h3 {
        strong {
            font-size: 17px;
        }
    }

    .onzecoating .section__title h2 {
        strong {
            font-size: 38px;
        }
    }

    .layer-block h3,
    .clients__title {
        font-size: 35px;
    }

    .sidebar__title {
        font-size: 25px;

        b,
        strong {
            font-size: 25px;
        }
    }

    .section__title--sm {
        font-size: 22px;
    }

    body {
        font-size: 16px;
    }

    .lead p {
        font-size: 20px;
    }

    .block {
        padding-bottom: 50px;
    }

    .block__title {
        font-size: 27px;
    }

    .block__subtitle {
        font-size: 17px;
    }

    .block__summary {
        margin: 25px 0;
    }

    .layer-block {
        .block__title {
            font-size: 22px;
        }
    }

    .block__content {
        padding-top: 30px;
    }

    .section__viewall {
        font-size: 22px;
    }

    .section__title--md,
    .text__dark--md {
        font-size: 35px;
    }

    .jumbotron {
        margin: 40px 0 0;
        padding: 10px 0 5px 40px;
        border-left-width: 8px;

        h3 {
            margin: 0 0 5px;
        }

        .lead {
            margin-bottom: 15px;
            font-size: 22px;
            line-height: 1.4;
        }
    }

    .archive__content {
        padding: 95px 0 40px;
    }

    .archive__list {
        padding: 0 0 70px;
    }

    .layer__value {
        margin-top: 80px;
        padding: 80px 0 40px;

        .block__links {
            li {
                background-position-y: 7px;
            }
        }

        .block__links__item {
            font-size: 24px;
        }

        h3 {
            font-size: 35px;
        }
    }

    .front__intro {
        h1 {
            strong {
                font-size: 38px;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    h1,
    .layer-block h3,
    .onzecoating .section__title h2,
    .advies__content .section__title h3,
    .projecten__title {
        margin-bottom: 30px;
        font-size: 33px;

        b,
        strong {
            font-size: 22px;
        }
    }

    .archive__content h1 strong,
    .archive__content h1 b {
        margin-top: -6px;
    }

    .advies__content .section__title h3 {
        strong {
            font-size: 17px;
        }
    }

    .onzecoating .section__title h2 {
        strong {
            font-size: 33px;
        }
    }

    .front__intro {
        h1 {
            strong {
                font-size: 33px;
            }
        }
    }

    .layer-block h3 {
        font-size: 26px;
    }

    .clients__title {
        font-size: 30px;
    }

    .section__title--sm {
        font-size: 20px;
    }

    .slider__top__prev,
    .slider__top__next {
        margin-top: -20px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba($white, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }

    .branche__content,
    .coating__content,
    .content__service,
    .oplossing__content,
    .projecten__content {
        padding-bottom: 0;
    }

    .layer {
        padding: 60px 0;
    }

    .clients {
        padding-bottom: 50px;
    }

    .layer__faq {
        padding-top: 60px;
    }

    .single__content {
        padding: 60px 0;
    }

    .single__content.bottom__content__none {
        padding-bottom: 0;
    }

    .archive__content {
        padding: 60px 0 30px;
    }

    .archive__list {
        padding: 0 0 35px;
    }

    .block {
        padding-bottom: 40px;
    }

    .block__subtitle {
        font-size: 15px;
    }

    .block__title {
        font-size: 25px;
    }

    .block__content {
        padding-top: 20px;
    }

    .section__viewall {
        margin-top: 40px;
        font-size: 20px;
    }

    .home .section__viewall {
        margin-top: 0;
    }

    .section__title--md,
    .text__dark--md {
        font-size: 30px;
    }

    .breadcrumb {
        bottom: 0;
    }

    h1,
    .layer-block h3,
    .layer-block .block__title,
    .onzecoating .section__title h2,
    .advies__content .section__title h3 {
        line-height: 1.4;
    }

    .breadcrumb-item + .breadcrumb-item {
        a {
            font-size: 15px;
        }

        &::before {
            margin: 0 10px;
        }
    }

    .breadcrumb__home {
        width: 20px;
        height: 20px;
    }

    .jumbotron {
        margin: 30px 0 20px;
        padding: 10px 0 5px 30px;
        border-left-width: 6px;

        .lead p {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }

    .layer__value {
        margin-top: 80px;
        padding: 80px 0 40px;

        .block__links {
            li {
                padding: 0 50px 11px 25px;
                background-position-y: 6px;
                background-size: 16px;
            }
        }

        .block__links__item {
            font-size: 20px;
        }

        h3 {
            font-size: 30px;
        }
    }

    .text--centered p:last-of-type {
        margin-bottom: 40px;
    }

    .text--centered__projecten p:last-of-type {
        margin-bottom: 10px;
    }

    .slider__nav__item__inner {
        img {
            border-radius: 20px;
        }
    }
}

@media screen and (max-width: 991px) {
    h1,
    .layer-block h3,
    .onzecoating .section__title h2,
    .advies__content .section__title h3,
    .projecten__title {
        margin-bottom: 20px;
        font-size: 30px;

        b,
        strong {
            font-size: 19px;
        }
    }

    .single__main--page {
        padding-right: 20px;
    }

    .advies__content .section__title h3 {
        strong {
            font-size: 17px;
        }
    }

    .onzecoating .section__title h2 {
        strong {
            font-size: 30px;
        }
    }

    .front__intro {
        h1 {
            strong {
                font-size: 30px;
            }
        }
    }

    .layer-block h3 {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 24px;
    }

    .clients__title {
        font-size: 24px;
    }

    .sidebar__title {
        font-size: 22px;

        b,
        strong {
            font-size: 22px;
        }
    }

    .section__title--sm {
        font-size: 20px;
    }

    .layer {
        padding: 50px 0;
    }

    .clients {
        padding-bottom: 40px;
    }

    .layer__faq {
        padding-top: 50px;
    }

    .block {
        padding-bottom: 40px;
    }

    .block__summary {
        margin: 20px 0;
    }

    .section__viewall {
        margin-top: 40px;
        font-size: 18px;
    }

    .home .section__viewall {
        margin-top: 0;
    }

    .section__title--md,
    .text__dark--md {
        font-size: 25px;
    }

    .single__content {
        padding: 50px 0;
    }

    .single__content.bottom__content__none {
        padding-bottom: 0;
    }

    .archive__content {
        padding: 50px 0 10px;

        h1,
        strong {
            margin-top: 0 !important;
        }
    }

    .archive__list {
        padding: 0 0 30px;
    }

    .text--centered {
        p:last-of-type {
            margin-bottom: 30px;
        }
    }

    .layer__value {
        margin-top: 40px;
        padding: 60px 0 30px;

        .block__links {
            margin-bottom: 0;

            li {
                background-position-y: 5px;
                background-size: 15px;
            }
        }

        .block__links__item {
            font-size: 18px;
        }

        h3 {
            font-size: 26px;
        }
    }

    h1,
    .layer-block h3,
    .layer-block .block__title,
    .onzecoating .section__title h2 {
        line-height: 1.2;
    }
}

@media screen and (max-width: 767px) {
    body,
    .lead p {
        font-size: 15px;
    }

    h1,
    .layer-block h3,
    .onzecoating .section__title h2,
    .advies__content .section__title h3,
    .projecten__title {
        margin-bottom: 10px;
        font-size: 25px;

        b,
        strong {
            font-size: 14px;
        }
    }

    .advies__content .section__title h3 {
        strong {
            font-size: 17px;
        }
    }

    .onzecoating .section__title h2 {
        strong {
            font-size: 25px;
        }
    }

    .front__intro {
        h1 {
            strong {
                font-size: 25px;
            }
        }
    }

    .layer-block h3 {
        margin-bottom: 30px;
        font-size: 22px;
    }

    .clients__title {
        font-size: 22px;

        strong {
            margin-top: 20px;
        }
    }

    .single__sidebar {
        .sidebar__title {
            margin-bottom: 18px;
            font-size: 21px;

            b,
            strong {
                font-size: 21px;
            }
        }
    }

    .section__title--sm {
        font-size: 20px;
    }

    .single__main {
        padding-bottom: 0;
    }

    .layer {
        padding: 40px 0;
    }

    .clients {
        padding-bottom: 30px;
    }

    .layer__faq {
        padding-top: 40px;
    }

    .block__summary {
        margin: 10px 0 18px 0;
        font-size: 15px;
    }

    .block__img {
        &::before {
            top: -7px;
            left: -3px;
            width: calc(100% + 4px) !important;
            height: calc(100% + 11px) !important;
        }
    }

    .section__viewall {
        margin-top: 20px;
        font-size: 17px;
    }

    .home .section__viewall {
        display: block;
        margin-top: -10px;
    }

    .projects .block__title__all {
        margin-bottom: 20px;
    }

    .section__title--md,
    .text__dark--md {
        font-size: 20px;
    }

    .single__content {
        padding: 40px 0;
    }

    .block__date {
        font-size: 15px;

        svg {
            width: 12px;
            height: 14px;
            margin: -7px 8px 0 0;
        }
    }

    .block__title {
        font-size: 20px;
    }

    .layer.archive__list {
        padding-top: 0;
        padding-bottom: 0;
    }

    .archive__content {
        padding: 30px 0 10px;
    }

    .layer.archive__content {
        padding: 35px 0 30px;
    }

    .single__content .btn--prev {
        margin-top: 15px;
    }

    .layer__value {
        margin-top: 30px;
        padding: 50px 0 30px !important;

        .block__links {
            justify-content: flex-start;
            max-width: 260px;
            margin: 0 auto;

            li {
                background-position-y: 5px;
                background-size: 15px;
            }
        }

        .block__links__item {
            font-size: 18px;
        }

        h3 {
            font-size: 22px;
        }
    }

    .text--centered p:last-of-type {
        margin-bottom: 0;
    }

    .team__list .block__img {
        max-width: 60%;
    }

    .advies {
        padding-right: 20px;
        padding-left: 20px;
    }

    #offerte-popup {
        max-width: 92%;
        padding: 30px 33px 33px;
        border-radius: 30px;
    }

    #offerte-popup .fancybox-button {
        top: 4px;
        right: 3px;
    }

    .onzecoating .block__links li {
        background-position: 20px 4px;
    }

    .team__list {
        .block__mobile svg {
            top: 2px;
        }
    }
}

@media screen and (max-width: 575px) {
    h1,
    .layer-block h3,
    .onzecoating .section__title h2,
    .advies__content .section__title h3,
    .projecten__title {
        font-size: 22px;

        b,
        strong {
            font-size: 17px;
        }
    }

    .advies__content .section__title h3 {
        strong {
            font-size: 17px;
        }
    }

    .onzecoating .section__title h2 {
        strong {
            font-size: 22px;
        }
    }

    .onzecoating .block__title {
        font-size: 27px;
    }

    .front__intro {
        h1 {
            strong {
                font-size: 22px;
            }
        }
    }

    .layer-block h3 {
        margin-bottom: 30px;
    }

    .sidebar__title {
        font-size: 17px;

        b,
        strong {
            font-size: 17px;
        }
    }

    .section__title--sm {
        font-size: 20px;
    }

    .layer {
        padding: 30px 0;
    }

    .layer__faq {
        padding-top: 40px;
    }

    .single__content {
        padding: 35px 0 !important;
    }

    .single__content.bottom__content__none {
        padding-bottom: 0 !important;
    }

    .col-form-label {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
    }

    select.form-control {
        height: 40px;
        font-size: 16px;
    }

    .section__viewall {
        margin-top: 20px;
    }

    .layer__value {
        margin-top: 30px;
        padding: 50px 0 20px !important;

        h3 {
            margin-bottom: 20px;
        }
    }

    .projects .slick-arrow {
        width: 25px;
        height: 25px;
    }
}
