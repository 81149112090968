/* Pagination numbered */
.pagination__numbers {
    border-top: 1px solid #eee;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .page-numbers {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: $primarycolor;
        display: flex;
        float: left;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 15px;
        margin: 0 3px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5);
            text-decoration: none;
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.single__date {
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
    color: #505050;

    svg {
        position: relative;
        top: -2px;
        width: 14px;
        height: 16px;
        margin-right: 8px;
        color: #505050;
        fill: #505050;
    }
}

.single__text {
    margin-bottom: 28px;
}
