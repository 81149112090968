/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #007672;
$secondarycolor: #5cb8b2;

$font-primary: 'geomanist', sans-serif;
$font-secondary: '';
$body-font-size: 18px;
$body-font-weight: 300;
$body-line-height: 1.7;
$body-font-color: $primarycolor;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: $font-primary;
$headings-line-height: 1.3;
$headings-color: $secondarycolor;
$headings-font-weight: 700;

// Header:
$header-height: 190px;

$border-radius: 25px;
$block-radius: 50px;

$theme-colors: (primary: $primarycolor);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 49px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 18px;
