@import "~bootstrap/scss/bootstrap";

@media (min-width: 1200px) {
    .container {
        max-width: 1276px;
    }

    .container-fluid {
        max-width: 1276px;
    }
}
