.btn {
    position: relative;
    padding: 13px 37px;
    border: 0;
    border-radius: $border-radius;
    margin-right: 8px;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
        color: #fff;
    }

    &:last-child {
        margin-right: 0;
    }

    svg {
        transform: translate(-15px, -3px);
    }
}

.btn--primary {
    background-color: $primarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--secondary {
    background-color: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($secondarycolor, 5) !important;
    }
}

.btn--tertiary {
    background-color: #cf6800;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten(#cf6800, 5) !important;
    }
}

.btn--next {
    padding-right: 38px;
    background-color: $primarycolor;

    &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 7px;
        height: 18px;
        margin-top: -9px;
        content: '';
        background-image: url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    background-color: $primarycolor;

    &::before {
        content: '';
        position: absolute;
        left: 30px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../images/angle-left-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

.single__main {
    .btn {
        margin-top: 15px;

        &.btn--prev {
            padding-left: 57px;
        }
    }
}

@media screen and (max-width: 1599px) {
    .btn {
        padding: 10px 30px;
        font-size: 16px;

        svg {
            transform: translate(-10px, -3px);
        }
    }

    .single__main {
        .btn.btn--prev {
            background-color: $primarycolor;
            padding-left: 40px;

            &::before {
                left: 17px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .btn {
        padding: 8px 20px;
        font-size: 15px;

        svg {
            transform: translate(-5px, -3px);
        }
    }
}
