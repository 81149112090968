@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
* Import npm dependencies
*
* Prefix your imports with `~` to grab from node_modules/
* @see https://github.com/webpack-contrib/sass-loader#imports
*/
// @import "~some-node-module";

// Import Slick from node_modules
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

// Import fontawesome via package manager
// @import "~@fortawesome/fontawesome-pro/css/all.css";

// Import Fancybox from node_modules
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "components/social-icons";
@import "layouts/header";
@import "layouts/banner";
@import "layouts/search";
@import "layouts/page-content";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/nieuws";
@import "layouts/pages";
@import "layouts/tinymce";
@import "layouts/front";
@import "layouts/breadcrumbs";

/** Autoload modules */
@import "modules/_branches.scss"; @import "modules/_coatings.scss"; @import "modules/_contact.scss"; @import "modules/_diensten.scss"; @import "modules/_downloads.scss"; @import "modules/_faq.scss"; @import "modules/_oplossingen.scss"; @import "modules/_popup.scss"; @import "modules/_projecten.scss"; @import "modules/_team.scss";

/** Import responsive styles */
@import "layouts/responsive-1199px+";
@import "layouts/responsive-1199px";
@import "layouts/responsive-991px";
@import "layouts/responsive-767px";
@import "layouts/responsive-575px";
@import "layouts/responsive-575px-";
