.header__notification__button {
    border-radius: 0 0 25px 25px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: 0.75px;
    padding-top: 8px;
    padding-bottom: 10px;
}

.header__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: $header-height;
}

.header__logo img {
    width: 100%;
    height: auto;
}

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;

    // styles when header shrinks after scroll
    &.header--fix {
        background: rgba($white, 0.95);
        box-shadow: 0 5px 10px rgba($black, 0.3);

        .header__notification__button {
            display: none;
        }

        .header__center {
            top: 7px;
            width: 150px;

            .header__logo {
                img {
                    &:first-child {
                        width: 150px;
                        height: 49px;
                    }
                }
            }
        }

        .header__right {
            top: 0;
            right: 10px;
        }

        .header__inner {
            align-items: center;
            height: auto;
            padding: 10px 0;
        }

        .header__menu__btn::before {
            display: none;
        }

        .header__icons {
            margin-bottom: 0;
        }

        .header__menu__text {
            color: $secondarycolor;
        }

        .header__menu__bar {
            background-color: $secondarycolor;
        }
    }
}

.header__left {
    display: flex;
    height: 100%;
}

.header__right {
    position: relative;
    top: 15px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header__center {
    position: absolute;
    top: 105px;
    right: 0;
    left: 0;
    width: 359px;
    margin: auto;
    transition: all 0.3s;
}

.header__icons {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 0 60px;

    svg {
        width: 22px;
        height: 22px;
        transform: none;
        color: $secondarycolor;

        &.fa-times {
            position: absolute;
            top: 13px;
            right: 10px;
            width: 15px;
            height: 17px;
        }
    }
}

.header__language__switcher {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 44px;
    margin-left: 20px;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid $secondarycolor;
    border-radius: 22px;

    a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 11px;
        color: $secondarycolor;

        &.active {
            color: $white;
            background: $secondarycolor;

            &:hover {
                color: $white;
                background: $primarycolor;
            }
        }

        &:hover {
            color: $primarycolor;
        }
    }
}

.header__search {
    padding-right: 15px;
    cursor: pointer;

    &.open {
        .header__search__input {
            right: calc(100% + 10px);
            transition: all 300ms ease-in-out;
            pointer-events: all;
            opacity: 1;

            .header__search__toggle {
                display: block;
            }
        }

        .header__search__toggle {
            display: none;
        }

        .header__search__submit {
            display: flex;
        }
    }

    form {
        position: relative;
        display: flex;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        margin: 0;
        padding: 0;
        border: 1px solid transparent;
        border-radius: 8px;
    }

    .header__search__input {
        position: absolute;
        right: 0;
        width: 250px;
        height: 42px;
        transition: all 300ms ease-in-out;
        font-size: 14px;
        pointer-events: none;
        opacity: 0;
        border-radius: 10px;
        background: $secondarycolor;

        &::after {
            position: absolute;
            top: calc(50% - 4px);
            left: 100%;
            width: 0;
            height: 0;
            content: '';
            border-width: 5px 0 5px 7px;
            border-style: solid;
            border-color: transparent transparent transparent $secondarycolor;
        }

        .search-field {
            width: calc(100% - 35px);
            height: 100%;
            padding-left: 15px;
            color: $white;
            border: none;
            outline: 0;
            background: none;

            &::placeholder {
                color: rgba($white, 0.4);
            }
        }
    }

    .header__search__submit {
        display: none;
    }
}

.header__menu .nav {
    flex-direction: column;
    transform: translateX(-50%);
    border-right: 2px solid rgba($white, 0.53);
}

.header__search__toggle {
    transition: all 0.3s;

    &:hover {
        svg {
            color: $primarycolor;
            fill: $primarycolor;
        }
    }
}

.header__menu__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    cursor: pointer;

    &::before {
        position: absolute;
        bottom: 0;
        left: 30px;
        display: inline-block;
        width: 662px;
        height: 260px;
        content: "";
        transform: translateX(-50%);
        pointer-events: none;
        background: url('../../svg/menu-bg.svg') no-repeat;
        background-size: cover;
    }

    &:hover {
        .header__menu__text {
            color: $secondarycolor;
        }

        .header__menu__btn__inner {
            span {
                background-color: $secondarycolor;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .header__menu__btn {
        &::before {
            background-size: 720px;
        }
    }
}

.header__menu__btn__inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 18px;
    height: 12px;
    transition: all 300ms ease-in-out;

    &:hover {
        .header__menu__bar {
            color: $secondarycolor;
        }
    }
}

.header__menu__bar {
    position: absolute;
    width: 18px;
    height: 2px;
    transition: all 300ms ease-in-out;
    transform: rotate(0deg);
    opacity: 1;
    background-color: $white;

    &.header__menu__bar--top {
        top: 0;
        left: 0;
        transform-origin: top left;
    }

    &.header__menu__bar--mid {
        top: 50%;
        left: 0;
        margin-top: -1px;
    }

    &.header__menu__bar--bot {
        bottom: 0;
        left: 0;
        transform-origin: bottom left;
    }
}

.header__menu__text {
    position: relative;
    padding-left: 10px;
    transition: all 0.3s;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    color: $white;
}

body {
    &.header__menu__open {
        position: fixed;

        &::before {
            position: fixed;
            z-index: 3;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: inline-block;
            content: "";
            background: radial-gradient(ellipse at center, $secondarycolor 0%, $primarycolor 100%);
        }

        .header__menu {
            transition: opacity 300ms ease-in-out;
            pointer-events: all;
            opacity: 1;
        }

        .header__menu__bar--top {
            top: 1px;
            height: 3px;
            transition: all 300ms ease-in-out;
            transform: rotate(45deg);
        }

        .header__menu__bar--mid {
            transition: all 300ms ease-in-out;
            opacity: 0;
        }

        .header__menu__bar--bot {
            bottom: 1px;
            height: 3px;
            transition: all 300ms ease-in-out;
            transform: rotate(-45deg);
        }

        .header__menu__btn__inner {
            height: 16px;
            transition: all 300ms ease-in-out;
        }

        .header__logo {
            display: none;
        }

        .header__language__switcher {
            display: none;
        }

        .header__search .btn {
            border-color: $secondarycolor;
        }

        .header__icons svg {
            path {
                fill: $white;
            }
        }

        .header__search__toggle,
        .social__icon__item {
            &:hover {
                border-color: #fff;
                background-color: #fff;

                svg {
                    path {
                        color: $primarycolor !important;
                        fill: $primarycolor !important;
                    }
                }
            }
        }

        .header__right {
            top: 60px;
        }
    }
}

.header__address {
    display: none;
    margin-right: 80px;
}

.header__menu__open .header__address {
    display: flex;
    color: $white;
}

.header__social {
    display: none;
}

.header__menu__open .header__social {
    display: block;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border: 1px solid $secondarycolor;
        border-radius: 8px;
    }
}

.header__address__left {
    padding-right: 30px;
    font-size: 26px;
    font-weight: 900;
}

.header__address__label {
    font-size: 17px;
    line-height: 1.6;
    text-transform: uppercase;
    color: $secondarycolor;
}

.header__address__country {
    line-height: 1;
    text-transform: uppercase;
}

.header__address__right {
    a {
        display: block;
        font-size: 17px;
        line-height: 1.4;
        color: $white;
    }
}

.menu-item-touch {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    display: block;
    width: 40px;
    height: 40px;
    background-color: transparent;
}

.header__menu {
    position: fixed;
    z-index: 9999;
    top: 200px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 100%;
    height: calc(100% - 190px);
    transition: opacity 300ms ease-in-out;
    pointer-events: none;
    opacity: 0;

    a {
        font-size: 17px;
        font-weight: 600;
        color: $body-font-color;
    }

    .active {
        > a {
            //color: $white;
        }

        &::before {
            //position: absolute;
            //right: 0;
            //display: inline-block;
            //height: 100%;
            //content: "";
            //transform: translateX(2px);
            //border-right: 2px solid $white;
        }
    }

    .menu-item {
        position: relative;
        margin: 13px 0;
        text-align: right;

        &:hover {
            &::before {
                position: absolute;
                right: 0;
                display: inline-block;
                height: 100%;
                content: "";
                transform: translateX(2px);
                border-right: 2px solid $white;
            }
        }
    }

    .menu-angle {
        display: none;

        .svg-inline--fa {
            width: 14px;
            height: 25px;
        }
    }

    .sub-menu {
        position: absolute;
        display: none;
        margin: 0;
        padding: 10px 0 40px 38px;
        list-style: none;

        li {
            margin: 0;
            text-align: left;

            &:hover {
                &::before {
                    display: none;
                }
            }

            &.active {
                &::before {
                    display: none;
                }
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            line-height: 35px;
            color: $white;

            &:hover {
                color: rgba(255, 255, 255, 0.53);
            }
        }

        .menu-angle {
            margin-left: 10px;

            .svg-inline--fa {
                width: 8px;
                height: 22px;
            }
        }
    }

    .menu-coatings {
        .sub-menu {
            margin-top: -75px;
        }
    }

    > ul {
        > li {
            &:hover,
            &:focus {
                > a {
                    color: $white;
                }
            }

            > a {
                padding: 0 36px;
                font-size: 26px;
                font-weight: 900;
                text-transform: uppercase;
                color: rgba($white, 0.53);
                display: inline-block;

                > .menu-angle {
                    margin-left: 10px;
                }
            }

            > .sub-menu {
                top: 0;
                right: 0;
                transform: translateX(100%);

                .sub-menu {
                    top: 0;
                    left: 100%;

                    li {
                        > a {
                            background-color: darken($primarycolor, 4);
                        }

                        &.active {
                            > a {
                                background-color: darken($primarycolor, 8);
                            }
                        }

                        &:hover,
                        &:focus {
                            > a {
                                background-color: darken($primarycolor, 8);
                            }
                        }

                        &:last-child {
                            a {
                                border-bottom: 1px solid darken($primarycolor, 4);
                            }
                        }
                    }
                }
            }
        }
    }
}

.logo__inner {
    display: none;
}

.header:not(.header--fix) .header__icons {
    svg,
    path {
        color: $white;
        fill: $white;
    }
}

body.page-template-contact .header:not(.header--fix) .logo__inner {
    display: none !important;
}

.header:not(.header--fix) .header__search__toggle {
    &:hover {
        svg,
        path {
            color: $secondarycolor;
            fill: $secondarycolor;
        }
    }
}

.header:not(.header--fix) .header__language__switcher {
    border-color: $white;

    a.active {
        color: $secondarycolor;
        background: $white;

        &:hover {
            color: $white;
            background: $primarycolor;
        }
    }

    a {
        color: $white;

        &:hover {
            color: $secondarycolor;
        }
    }
}

.header:not(.header--fix) .logo__home {
    display: none;
}

.header:not(.header--fix) .logo__inner {
    display: block;
}

.header__search__input .fa-times {
    color: #fff !important;
    fill: #fff !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .header__menu {
        .sub-menu {
            li {
                list-style-image: url(data:0);
            }
        }
    }
}

@media screen and (max-width: 1599px) {
    $header-height: 150px;

    .header__inner {
        align-items: center;
        height: $header-height;
    }

    .header__center {
        width: 260px;
    }

    .header__icons {
        margin-bottom: 0;
    }

    .header__menu .menu-item {
        margin: 15px 0;
    }

    .header__address__left {
        padding-right: 20px;
    }

    .header__address {
        margin-right: 50px;
    }

    body.header__menu__open .header__right {
        top: 40px;
    }

    .header__menu {
        top: 190px;
        height: calc(100% - #{$header-height});
    }
}

@media screen and (max-width: 1199px) {
    .header__address {
        display: none !important;
    }

    .header__menu > ul > li > a {
        font-size: 20px;
    }

    .header__center {
        top: unset;
        bottom: 5px;
        width: 250px;
    }

    .header__menu .sub-menu {
        padding-top: 0;
    }

    body {
        &.header__menu__open {
            .header__right {
                top: 15px;
            }
        }
    }

    .header__menu {
        top: 115px;
    }

    .header__menu .menu-angle {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 52px;
        margin: -10px 0 0 0;
        transform: rotate(-90deg);
    }
}

@media screen and (max-width: 991px) {
    .header__icons svg {
        width: 15px;
        height: 15px;
    }

    .header__center {
        bottom: 15px;
        width: 200px;
    }

    .header__menu {
        top: 190px;
        display: block;
        padding: 0 20px;

        a {
            color: $white;
        }
    }

    .header__menu .active > a {
        color: $white;
    }

    .header__menu .menu-coatings .sub-menu {
        margin-top: 0;
    }

    .header__menu .menu-projecten .sub-menu {
        margin-top: 0;
    }

    .header__menu > ul > li > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        padding: 12px 40px 12px 0;
        font-size: 17px;
        border-bottom: 1px solid lighten($primarycolor, 5);
    }

    .header__menu > ul > li > a > .menu-angle {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 52px;
        margin: 0;
        transform: rotate(0deg);
    }

    .header__menu .menu-angle .svg-inline--fa {
        width: 20px;
        height: 22px;
        transition: transform 300ms ease-in-out;
        transform: rotate(0deg);
    }

    .touchevents .menu-item-touch {
        position: absolute;
        top: 0;
        right: 0;
        left: initial;
        display: block;
        width: 40px;
        height: 52px;
    }

    .header__menu > ul > li:hover > a,
    .header__menu > ul > li:focus > a {
        color: $white;
    }

    .header__menu .sub-menu {
        position: relative;
        top: initial !important;
        right: initial !important;
        left: initial !important;
        transform: none !important;

        .menu-angle {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    .header__menu .sub-menu a {
        display: flex;
        justify-content: flex-start;
        font-size: 15px;
        font-weight: 600;
    }

    .header__menu .sub-menu a::before {
        width: 7px;
        // KLEUR VERANDEREN: in background data image: je ziet hier een style='color: ;'. Plaats de kleurcode achter %23. (%23 staat voor #)
        height: 14px;
        margin: 9px 10px 0 8px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg style='color: %235cb8b2;' aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' class='svg-inline--fa fa-angle-right fa-w-6' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='currentColor' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 6px;
    }

    .header__menu .sub-menu a .menu-angle {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 52px;
    }

    .menu-item.open > .menu-item-touch {
        display: block;
    }

    .menu-item.open > .sub-menu {
        display: block !important;
        padding: 0;
    }

    .menu-item.open > a .menu-angle svg {
        transition: transform 300ms ease-in-out;
        transform: rotate(180deg);
    }

    .sub-menu > .menu-item.open > a .menu-angle svg {
        transition: transform 300ms ease-in-out;
        transform: rotate(270deg);
    }

    .header__menu > ul > li > .sub-menu > li a {
        padding: 0 20px;
        background-color: rgba($primarycolor, 0.5);
    }

    .header__menu > ul > li > .sub-menu > li:first-of-type {
        a {
            padding-top: 20px;
        }
    }

    .header__menu > ul > li > .sub-menu > li:last-of-type {
        a {
            padding-bottom: 20px;
        }
    }

    .header__menu .nav {
        display: block;
        transform: none;
        border: none;
    }

    .header__menu .menu-item {
        margin: 0;
        text-align: left;

        &.active,
        &:hover {
            &::before {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    $header-height: 70px;

    .header__notification__button {
        left: inherit;
        top: inherit;
        right: 0;
        bottom: 20px;
        border-radius: 25px;
        padding: 10px 25px;
        transform: translateX(-10%);
        z-index: 2500;
    }

    .header__inner {
        height: $header-height;
    }

    .header__menu__btn::before {
        display: none;
    }

    .header__menu__bar {
        background: $secondarycolor;
    }

    .header__menu__text {
        color: $secondarycolor;
    }

    .header__left {
        order: 4;
    }

    .header__menu__open .header__center {
        display: none;
    }

    .header__center {
        position: static;
        width: 170px;
        margin-top: 15px;
        margin-left: 0;
    }

    .header__language__switcher {
        margin: 0 10px;
    }

    .header.header--fix {
        box-shadow: 0 2px 6px rgba($black, 0.3);

        .header__notification__button {
            display: inline-block;
        }

        .header__center {
            margin-top: 10px;
        }
    }

    header:not(.header--fix) .header__menu__text {
        color: $white;
    }

    .header:not(.header--fix) .header__menu__bar {
        background-color: $white;
    }

    .header__menu__open .header:not(.header--fix) .header__menu__text {
        color: $white;
    }

    .header__menu__open .header:not(.header--fix) .header__menu__bar {
        background-color: $white;
    }

    .header__menu {
        top: $header-height;
        height: calc(100% - #{$header-height});
    }

    .header__search {
        padding-right: 15px;
    }

    .header__right {
        top: unset;
        right: unset;
    }

    .header__search .header__search__input {
        right: unset;
        left: calc(100% + 10px);

        &::after {
            right: 100%;
            left: unset;
            transform: rotate(180deg);
        }
    }

    .header__search.open .header__search__input {
        right: unset;
        left: calc(100% + 10px);

        &::after {
            right: 100%;
            left: unset;
            transform: rotate(180deg);
        }
    }

    body.header__menu__open .header__right {
        top: unset;
    }
}

@media screen and (max-width: 575px) {
    .header__search {
        display: none;
    }

    .header__menu__open .header__search {
        display: flex;
    }

    .header__language__switcher {
        position: absolute;
        right: -100px;
        top: 25px;
        height: 30px;

        a {
            padding: 0 8px;
        }
    }

    .header {
        &.header--fix {
            .header__language__switcher {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .header__menu .nav {
        padding: 10px 20px;
    }

    .header__menu > ul > li > a {
        font-size: 15px;
    }

    .header__menu .sub-menu a {
        min-width: auto;
        font-size: 14px;
    }

    .header__search .header__search__input {
        width: 190px;
    }
}

@media screen and (min-width: 992px) {
    .header__menu > ul > li:hover .sub-menu {
        display: block;
    }
}
