.categories {
    background: #f0f0f0;
    border-radius: 10px;
    padding: 3px 45px 28px 45px;
}

.categories__title {
    margin-top: 26px;
}

.categories__list {
    list-style-type: none;
    padding: 0;
}

.categories__item {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #505050;
    background: #d9d9d9;
    border-radius: $border-radius;
    text-transform: uppercase;
    padding: 10px 21px;
    margin-bottom: 11px;

    &:hover {
        &::after {
            right: 13px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        margin-top: 5px;
        width: 6px;
        height: 18px;
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
        transition: all 0.3s;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .single__main--coating {
        order: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .single__main--coating {
        margin-bottom: 0;
    }

    .categories {
        padding: 1px 35px 28px 35px;
    }
}

@media screen and (max-width: 575px) {

}
