.project__filter {
    padding-top: 0;
}

.project__filter .form-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

.project__filter__block {
    display: flex;
    align-items: center;

    .searchandfilter {
        ul {
            padding: 0;
        }
    }
}

.project__toggle {
    height: 54px;
    padding: 0 70px 0 35px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    line-height: 54px;
    text-align: left;
    text-transform: uppercase;
    color: $primarycolor;
    border-radius: 27px;
    background: rgba($primarycolor, 0.1) url(../../svg/angle-down-regular.svg) no-repeat 90% center;
    background-size: 20px 20px;
}

.project__filter__content {
    display: none;
    width: 100%;
    padding: 37px 0 0 0;

    .searchandfilter {
        > ul {
            margin: 0;
            padding: 0;

            > li {
                margin: 0;
                padding: 0;

                > ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;

                    > li {
                        flex: 0 0 25%;
                        max-width: 25%;
                        padding: 0;
                        margin-bottom: 2px;

                        &.sf-level-0 {
                            position: relative;

                            input[type=checkbox]:checked + label {
                                background-color: #fff;

                                &::after {
                                    opacity: 1;
                                    transition: all 100ms ease-in-out;
                                }
                            }

                            .sf-input-checkbox {
                                display: none;
                            }

                            label {
                                background-repeat: no-repeat;
                                background-position: 65px center;
                                padding: 0 25px 0 35px !important;
                                background-color: #fff;
                                cursor: pointer;
                                width: 100%;
                                height: 31px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;

                                &::before {
                                    content: '';
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 1px solid #007672;
                                    border-radius: 5px;
                                    position: absolute;
                                    left: 0;
                                    top: 5px;
                                }

                                &::after {
                                    content: '';
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    left: 0;
                                    top: 5px;
                                    background-image: url('../../svg/check-solid-green.svg');
                                    background-size: 12px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    opacity: 0;
                                    transition: all 100ms ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.project__filter--open .project__filter__content {
    display: block;
}

.project__filter--open .project__toggle {
    background-image: url(../../svg/angle-up-regular.svg);
}

.project__filter .checkmarkcontainer {
    margin-bottom: 15px;
}

// Project Slider
.project__inner .slick-list {
    //overflow: visible; IE FIX
}

.project__inner .slick-initialized .slick-slide {
    position: relative;

    .slider__right__before {
        svg {
            position: absolute;
            z-index: 2;
            top: -10px;
            left: -10px;
            display: inline-block;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            content: "";
            pointer-events: none;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.project__inner .slider__nav .slick-slide {
    &::before {
        display: none;
    }

    .slider__nav__item__inner {
        position: relative;

        &::before {
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
            content: "";
            pointer-events: none;
            //background: url(../../images/project-slider-bg2.png) no-repeat;
            background-size: cover;
        }

        &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#007672, 0.5);
            border-radius: 10px;
            transition: all 0.3s;
        }

        img {
            border-radius: 10px;
        }

        &:hover {
            &::after {
                background-color: transparent;
            }
        }
    }
}

.sf-input-select.project__toggle,
.project__toggle.project__toggle--projecten {
    min-width: auto !important;
    padding: 0 60px 0 35px;
    border: none;
    appearance: none;
}

.project__inner .slick-initialized.slider__content .slick-slide {
    padding: 10px;
}

.project__inner .slick-initialized.slider__nav .slick-slide::before {
    left: 11px;
    width: calc(100% - 24px);
}

.project__inner .slider__top__prev svg,
.project__inner .slider__top__next svg {
    width: 40px;
    height: 40px;
    color: $white;
}

.project__inner .slick-slide img {
    width: 100%;
    border-radius: 40px;
}

[class*="layer__slider"] .slick-prev {
    top: 30%;
    left: -146px;
    display: inherit !important;
}

[class*="layer__slider"] .slick-next {
    top: 25%;
    right: -146px;
    display: block !important;
    transform: rotate(180deg);
    left: auto;
}

.onzecoating [class*="layer__slider"] {
    .slick-prev {
        top: 43%;
        left: -135px;
    }

    .slick-next {
        top: 39%;
        right: -135px;
    }
}

.layer__threecol {
    margin: 0;
    padding-top: 80px;
    padding-left: 0;

    .section__viewall {
        margin-top: 30px;
    }
}

.layer-block {
    &:last-of-type {
        padding-bottom: 0 !important;
    }
}

[class*="layer__slider"] .onzecoating__item {
    padding-right: 0;
}

.layer__slider2 {
    padding-left: 15px;
}

.layer__slider2 .projects__item {
    position: relative;

    &::after {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: inline-block;
        width: 10px;
        height: 100%;
        content: "";
        pointer-events: none;
        background: $white;
    }
}

.onzecoating.layer__threecol .section__viewall {
    margin-top: 10px;
}

@media screen and (max-width: 1599px) {
    .layer__threecol {
        max-width: 1160px;
        margin: auto;
        padding: 80px 50px 0;
    }

    .layer__slider2 .block__img::before {
        width: calc(100% + 14px);
    }

    .onzecoating [class*="layer__slider"] .slick-prev {
        left: -50px;
    }

    .onzecoating [class*="layer__slider"] .slick-next {
        right: -50px;
    }

    .layer__threecol [class*="layer__slider"] .slick-prev {
        left: -50px;
    }

    .layer__threecol [class*="layer__slider"] .slick-next {
        right: -50px;
    }

    [class*="layer__slider"] .onzecoating__item {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 1199px) {
    .layer__threecol {
        padding-top: 60px;
    }

    .layer__threecol [class*="layer__slider"] .slick-prev {
        left: -40px;
    }

    .layer__threecol [class*="layer__slider"] .slick-next {
        right: -40px;
    }

    .layer__threecol .section__viewall {
        margin-top: 10px;
    }

    .project__inner .slick-slide img {
        border-radius: 25px;
    }

    .project__inner .slider__nav .slick-slide .slider__nav__item__inner img {
        border-radius: 12px;
    }

    [class*="layer__slider"] .slick-prev {
        top: 28%;
    }

    [class*="layer__slider"] .slick-next {
        top: 22%;
    }

    .onzecoating .layer__slider1 .block__content {
        padding: 100px 65px;
    }

    .project__filter__content .searchandfilter > ul > li > ul > li {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
}

@media screen and (max-width: 991px) {
    .single__main--projecten {
        margin-bottom: 40px;
    }

    .single__sidebar--projecten {
        margin-top: 0;
        margin-bottom: 0;
    }

    .layer__threecol {
        max-width: 800px;
        padding-top: 50px;
    }

    [class*="layer__slider"] .slick-next {
        top: 24%;
    }

    .onzecoating .layer__slider1 .block__content {
        padding: 60px 65px;
    }
}

@media screen and (max-width: 767px) {
    .layer__threecol {
        padding-top: 40px;
    }

    .single__main--projecten {
        margin-bottom: 15px;
    }

    .layer.project__filter {
        padding-bottom: 30px;
    }

    .onzecoating .layer__slider1 .block__content {
        padding: 55px 45px;
    }

    [class*="layer__slider"] .onzecoating__item {
        padding-left: 0;
        padding-right: 0;
    }

    .project__filter__content {
        padding: 30px 0 0 0;
    }

    .project__filter__content .searchandfilter > ul > li > ul > li {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0;
    }

    .project__filter__content .searchandfilter > ul > li > ul > li.sf-level-0 label {
        padding: 0 25px 0 27px !important;
        height: 26px;
    }

    .project__filter__content .searchandfilter > ul > li > ul > li.sf-level-0 label::before {
        width: 17px;
        height: 17px;
        top: 4px;
    }

    .project__filter__content .searchandfilter > ul > li > ul > li.sf-level-0 label::after {
        width: 17px;
        height: 17px;
        top: 4px;
        background-size: 10px;
    }
}

@media (max-width: 575px) {
    .project__filter__content .searchandfilter > ul > li > ul > li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .onzecoating .layer__slider1 .block__content {
        padding: 49px 22px;
    }

    .onzecoating.layer__threecol .block__links {
        padding-right: 20px;
        padding-left: 20px;
    }

    .onzecoating.layer__threecol .block__links li {
        padding-bottom: 3px;
        background-position: 18px 3px;
        padding-right: 15px;
        font-size: 16px;
    }

    .onzecoating .block {
        height: calc(100% - 10px);
        margin-bottom: 10px;
    }

    .project__filter__block {
        flex-direction: column;
    }

    .projects__item {
        padding-right: 15px;
    }

    .layer__threecol {
        padding: 30px 20px 0;

        .section__viewall {
            margin-top: 0;
        }
    }

    .layer-block h3 {
        margin-bottom: 20px;
        margin-top: 13px;
    }

    .projects__slider .block,
    .layer__slider2 .block {
        height: calc(100% - 30px);
        margin-bottom: 30px;
    }

    .projects .layer__slider2 .block__title {
        margin-bottom: 30px;
    }

    [class*=layer__slider] .slick-prev {
        left: -20px;
    }

    [class*=layer__slider] .slick-next {
        right: -20px;
    }

    .layer__threecol [class*="layer__slider"] .slick-prev {
        left: -20px;
    }

    .layer__threecol [class*="layer__slider"] .slick-next {
        right: -20px;
    }

    .onzecoating.layer__threecol [class*="layer__slider"] .slick-prev {
        left: -20px;
        top: 50%;
        margin-top: -17px;
    }

    .onzecoating.layer__threecol [class*="layer__slider"] .slick-next {
        right: -20px;
        top: 50%;
    }

    .project__filter__content .checkmark {
        top: 3px;
    }
}

@media (max-width: 374px) {
    .onzecoating.layer__threecol [class*="layer__slider"] .slick-prev {
        left: -27px;
    }

    .onzecoating.layer__threecol [class*="layer__slider"] .slick-next {
        right: -27px;
    }
}

@media (max-width: 359px) {
    .onzecoating.layer__threecol .block__links li {
        font-size: 15px;
        height: 24px;
        overflow: hidden;
        padding-right: 16px;
    }
}
