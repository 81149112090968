.contact {
    .banner {
        &:hover {
            &::before {
                pointer-events: none;
            }
        }
    }
}

.layer.contact {
    padding: 100px 0 100px;
}

.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: $primarycolor;
}

.marker__item {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.contact__main {
    h1 {
        margin-bottom: 30px;
    }

    .contact__locations {

    }
}

.contact__form__inner {
    padding: 35px 40px;
    border-radius: $border-radius;

    .gform_title {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
    }

    .gform_description {
        margin-bottom: 10px;
    }

    .gfield {
        input,
        select,
        textarea {
            border: 1px solid transparent;
            border: 1px solid #d5d5d5;
            background-color: #fff;
        }

        textarea {
            max-height: 180px;
        }
    }
}

.contact__form__title {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.contact__locations {
    position: relative;
    padding-left: 48px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 100%;
        content: "";
        background-color: #def1f0;
    }

    &:first-of-type {
        margin-bottom: 40px;
    }
}

.contact__address {
    margin-bottom: 0;

    &:last-of-type {
        margin-bottom: 15px;
    }
}

.contact__company {
    margin-bottom: 0;
    font-size: $body-font-size;
    color: #000;
}

.contact__title {
    margin-right: 17px;
    font-weight: 600;
    min-width: 89px;
    display: inline-block;
}

.opening__hours {
    p {
        &:first-of-type,
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.contact__accessibility {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    line-height: 31px;

    li {
        margin-bottom: 5px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.contact__icon {
    margin-right: 17px;

    svg {
        position: relative;
        top: -4px;
        width: 18px;
        height: 18px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__text {
    transition: all 0.3s;
    color: $body-font-color;
}

.contact__details {
    margin-bottom: 20px;
}

.contact__link {
    &:hover {
        .contact__text {
            color: $primarycolor;
        }
    }
}

.contact__line__col {
    width: 75px;
    font-weight: bold;
}

.contact__image {
    max-width: 90%;
    margin-top: 50px;
}

@media screen and (max-width: 1199px) {
    .contact__form__inner {
        .gform_title {
            font-size: 26px;
        }
    }

    .layer.contact {
        padding: 60px 0 35px;
    }
}

@media screen and (max-width: 991px) {
    .contact__main,
    .contact__form {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 40px;
    }

    .contact__row {
        display: flex;
        justify-content: space-between;
    }

    .contact__title,
    .contact__icon {
        margin-right: 10px;
    }

    .contact__form__inner {
        .gform_title {
            font-size: 24px;
        }
    }

    .contact__locations {
        &:last-child {
            margin-left: 15px;
        }
    }
}

@media screen and (max-width: 767px) {
    .contact__form__inner {
        padding: 0;

        .gform_title {
            font-size: 20px;
        }
    }

    .contact__image {
        max-width: 100%;
        margin-top: 35px;
    }

    .layer.contact {
        padding: 35px 0 30px;
    }

    .contact__row {
        display: block;
    }

    .contact__locations {
        &:last-child {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 575px) {
    .contact__locations {
        position: relative;
        padding-left: 25px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            content: "";
            background-color: #def1f0;
        }

        &:first-of-type {
            margin-bottom: 30px;
        }
    }

    .contact__company {
        font-size: 17px;
    }

    .contact__address {
        &:last-of-type {
            margin-bottom: 10px;
        }
    }

    .contact__icon {
        margin-right: 7px;

        svg {
            width: 15px;
            height: 15px;
        }
    }
}
