.breadcrumb__main {
    background: none;
    position: absolute;
    padding: 0;
    bottom: 33px;
    z-index: 2;
    display: flex;
    align-items: center;
}

.breadcrumb__list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: $white;

    .fa-home {
        width: 20px;
        height: 18px;
        margin-top: -7px;
    }

    .fa-angle-right {
        width: 9px;
        height: 19px;
        margin: -6px 15px 0 15px;
        color: $white;
    }

    a {
        color: $white;

        &:hover {
            color: $secondarycolor;
        }
    }

    .breadcrumb__list__last {
        color: $white;
    }
}

@media screen and (max-width: 1199px) {
    .breadcrumb__main {
        bottom: 15px;
    }

    .breadcrumb__list {
        font-size: 15px;

        .fa-angle-right {
            width: 7px;
            height: 17px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .breadcrumb__home {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 991px) {
    .breadcrumb__main {
        bottom: 15px;
    }
}

@media screen and (max-width: 767px) {
    .breadcrumb__list {
        display: flex;
        flex-wrap: wrap;

        li {
            display: inline-block;
        }
    }
}
