.team__list .block__img::before {
    background-image: url(../../svg/team-bg.svg);
    top: -5px;
    height: calc(100% + 14px);
    width: calc(100% + 7px);
}

.team__list .block__title {
    font-size: 18px;
}

.team__list .block__subtitle {
    font-weight: 300;
    font-size: 18px;
    color: $primarycolor;
    text-transform: unset;
    margin-bottom: 15px;
}

.team__list .block__img__inner {
    border: 1px solid $secondarycolor;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .team__list .block__subtitle {
        font-size: 17px;
    }

    .team__list .block__phone svg,
    .team__list .block__email svg {
        width: 18px;
        height: 18px;
        top: 3px;
    }
}

@media screen and (max-width: 575px) {

}
