.search-form {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;

    label {
        flex: 1;
        margin-bottom: 0;
        max-width: 285px;
    }

    .search-submit {
        @extend .btn--primary;

        border: none;
        padding: 10px 35px 10px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 62px;
        outline: none;
        color: #fff;
        margin-left: 15px;

        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
        }
    }
}

.search-field {
    margin: 0;
    padding: 8px 20px 8px 20px;
    width: 100%;
    border: 1px solid #d5d5d5;
    border-radius: 50px;
    outline: none;
    resize: none;
    font-size: 14px;
    color: #000;
    height: 45px;
}

.search__content {
    position: relative;
}

.search__word {
    font-size: 23px;
    color: #000;
    display: block;

    b {
        font-weight: 700;
    }
}

.search__word__amount {
    display: block;
    margin: 30px 0 30px;
}

.search__amount {
    display: block;
    font-size: 18px;
    color: $primarycolor;
}

.search__result__row {
    position: relative;
    display: block;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ececec;

    &:hover {
        text-decoration: none;

        .search__result__title {
            color: #000;
        }
    }
}

.search__result__title {
    color: $primarycolor;
    margin-bottom: 3px;
    display: block;
    font-size: 18px;
    font-weight: 600;
    transition: color 0.3s;
}

.search__result__path {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #5cb8b2;

    svg {
        position: relative;
        top: -3px;
        height: 14px;
        width: 5px;
        margin: 0 10px;
        fill: #5cb8b2;
    }
}

.search__result__text {
    display: block;
    color: $body-font-color;
}

@media screen and (max-width: 767px) {
    .search__word {
        font-size: 20px;
    }

    .search__amount {
        font-size: 16px;
    }

    .search__word__amount {
        margin: 20px 0 20px;
    }

    .search__result__title {
        font-size: 16px;
    }

    .search__result__path {
        font-size: 14px;
    }
}
