.single__sidebar > div:not(:last-child) {
    margin-bottom: 30px;
}

.single__sidebar .toegevoegde__block {
    padding: 60px 0 100px 30px;
    min-height: 520px;
    justify-content: flex-start;

    &::before {
        top: 0;
        bottom: 0;
        width: 38%;
        content: "";
    }

    &::after {
        position: absolute;
        top: 0;
        right: 1px;
        display: inline-block;
        width: 170%;
        height: 100%;
        content: "";
        transform: translateX(100%);
        background: #def1f0;
    }
}

.sidebar__block {
    padding: 45px 58px;
    border-radius: $block-radius;
    background: #def1f0;
}

.sidebar__block .checkmarkcontainer {
    margin-bottom: 15px;
}

.single__sidebar .block__links {
    font-size: 24px;

    li {
        background-position-y: 8px;
    }

    .block__links__item {
        font-weight: 300;
    }
}

.sidebar__contact,
.sidebar__nieuws {
    display: block;
    padding: 27px 40px 38px;
    border-radius: $block-radius;
    background-color: #f3f3f3;
}

.sidebar__contact {
    .btn {
        display: block;
        margin-right: 0;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.sidebar__contact__text {
    display: block;
    margin-bottom: 20px;
}

.sidebar__nieuws__item {
    position: relative;
    display: block;
    margin-right: 0;
    padding: 7px 18px 4px;
    text-align: left;
    color: $body-font-color;
    border-radius: $border-radius;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &:hover {
        color: $body-font-color;

        .sidebar__nieuws__arrow {
            left: 5px;
        }
    }
}

.sidebar__nieuws__title {
    display: block;
    overflow: hidden;
    padding-left: 13px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.1;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar__nieuws__date {
    padding-left: 13px;
    font-size: 15px;
    font-weight: 600;
}

.sidebar__nieuws__arrow {
    position: absolute;
    top: 15px;
    left: 0;
    transition: all 0.3s ease;
    transform: translateY(-50%);

    svg {
        width: 12px;
        height: 20px;
        color: $body-font-color;
        fill: $body-font-color;
    }
}

.page__sidebar {
    .btn:not(:last-of-type) {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1599px) {
    .single__sidebar [class*=section__title] {
        font-size: 25px;
    }

    .single__sidebar .block__links {
        font-size: 22px;
    }

    .sidebar__block {
        padding: 40px 50px;
        border-radius: 40px;
    }

    .sidebar__block .checkmarkcontainer {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1199px) {
    .single__sidebar .block__links {
        font-size: 20px;
    }

    .sidebar__block {
        padding: 30px 40px;
        border-radius: 30px;
    }

    .sidebar__block .checkmarkcontainer {
        margin-bottom: 5px;
    }

    .single__sidebar .toegevoegde__block {
        padding: 40px 0 40px 18px;
    }

    .single__sidebar .block__links li {
        background-position-y: 7px;
    }
}

@media screen and (max-width: 991px) {
    .single__sidebar {
        max-width: 400px;
        margin: 40px 0;
    }

    .single__sidebar .block__links {
        font-size: 18px;
    }

    .single__sidebar .toegevoegde__block {
        padding: 35px 35px 40px 40px;
        min-height: 1px;
    }

    .single__sidebar .toegevoegde__block::after {
        display: none;
    }

    .sidebar__block {
        padding: 20px 30px;
        border-radius: 20px;
    }

    .single__sidebar .block__links li {
        background-position-y: 5px;
    }
}

@media screen and (max-width: 767px) {
    .single__sidebar {
        margin: 30px 0;
    }

    .sidebar__contact,
    .sidebar__nieuws {
        padding: 23px 35px 33px;
    }

    .single__sidebar [class*=section__title] {
        font-size: 22px;
    }

    .single__sidebar .block__links {
        font-size: 16px;
    }

    .sidebar__nieuws__title {
        padding-left: 6px;
        font-size: 16px;
    }

    .sidebar__nieuws__date {
        padding-left: 6px;
        font-size: 12px;
    }

    .sidebar__nieuws__arrow {
        top: 13px;

        svg {
            width: 8px;
            height: 15px;
        }
    }

    .single__sidebar .block__links li {
        background-position-y: 4px;
    }

    .single__sidebar--faq {
        .sidebar__title {
            position: relative;
            margin-bottom: 0;
            font-size: 18px;
            padding: 17px 25px 18px;
            cursor: pointer;

            &::after {
                position: absolute;
                top: 50%;
                right: 20px;
                width: 18px;
                height: 18px;
                content: "";
                transform: translateY(-50%);
                background-image: url('../../images/angle-down-regular.svg');
                background-size: 18px 18px;
            }
        }

        .sidebar__content {
            height: 0;
            padding: 0;
            pointer-events: none;
            opacity: 0;
        }

        .sidebar__block {
            padding: 0;

            &.active {
                .sidebar__content {
                    height: 100%;
                    padding: 0 30px 20px;
                    pointer-events: all;
                    opacity: 1;
                }

                .sidebar__title {
                    transition: all 0.3s;

                    &::after {
                        background-image: url('../../images/angle-up-regular.svg');
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .single__sidebar {
        max-width: 100%;
        margin: 20px 0;
    }
}
