.download {
    padding-top: 0 !important;
}

.page-template-downloads {
    h1 {
        margin-bottom: 10px;
    }

    .text--centered p:last-of-type {
        margin-bottom: 16px;
    }
}

.download__heading {
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    color: $black;
    margin-bottom: 17px;
}

.download__block__title {
    font-weight: 600;
    color: $primarycolor;
    margin-bottom: 12px;
}

.download__block__item {
    position: relative;
    height: 64px;
    display: flex;
    line-height: 1.2;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid $secondarycolor;
    border-radius: 32px;
    padding: 0 30px;
    margin-bottom: 16px;

    &:hover {
        background: $secondarycolor;
        color: $white;
    }
}

.download__block__txt {
    width: 100%;
    height: 23px;
    padding-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.download__block__item:hover .download__block__icon {
    background-image: url(../../svg/download-solid-white.svg);
}

.download__block__icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    min-width: 17px;
    min-height: 17px;
    background: url(../../svg/download-solid.svg) no-repeat;
    transition: all 300ms ease-in-out;
}

.download__block {
    padding-bottom: 15px;
}

.download__inner:not(:last-child) {
    margin-bottom: 60px;
}

@media (max-width: 1599px) {
    .download__heading {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .download__inner:not(:last-child) {
        margin-bottom: 50px;
    }

    .download__block__title {
        margin-bottom: 7px;
    }

    .download__block__item {
        height: 55px;
    }
}

@media (max-width: 1199px) {
    .download__heading {
        font-size: 22px;
        margin-bottom: 0;
    }

    .download__inner:not(:last-child) {
        margin-bottom: 40px;
    }

    .download__block__item {
        height: 45px;
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .download__inner:not(:last-child) {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .download__inner:not(:last-child) {
        margin-bottom: 10px;
    }

    .download__block {
        padding-bottom: 7px;
    }

    .download__block__item {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .page-template-downloads .text--centered p:last-of-type {
        margin-bottom: 0;
    }
}
