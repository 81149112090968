.footer {
    background: radial-gradient(ellipse at center, $secondarycolor 0%, $primarycolor 100%);
    padding: 210px 0 50px;
    line-height: 1.3;
    position: relative;
    overflow: hidden;

    &::after {
        display: inline-block;
        content: "";
        border-left: 100vw solid transparent;
        border-right: 0 solid transparent;
        border-top: 65px solid $white;
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }

    &.footer__after__bgc {
        &::after {
            border-top-color: #def1f0;
        }
    }
}

.footer__text {
    font-weight: 400;
    font-size: 28px;
    color: $white;

    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: #92d8d3;
        }
    }
}

.footer__inner {
    max-width: 740px;
    margin: auto;
}

.footer__logo {
    padding: 75px 0 105px;
    text-align: center;
}

.footer__menu {
    margin-top: 5px;
    text-align: center;
}

.copyright__text {
    font-size: 14px;
    color: $secondarycolor;
    padding: 0 16px;
    line-height: 30px;
    position: relative;

    &::after {
        content: "|";
        display: inline-block;
        position: absolute;
        right: -1px;
    }
}

.footer__menu .nav {
    justify-content: center;
    display: inline-block;

    li {
        font-size: 14px;
        color: $secondarycolor;
        padding: 0 16px;
        line-height: 30px;
        position: relative;
        display: inline-block;

        a {
            display: inline-block;
            color: $secondarycolor;
            line-height: 30px;

            &:hover {
                color: $white;
            }
        }

        &:not(:last-child)::after {
            content: "|";
            display: inline-block;
            position: absolute;
            right: -1px;
        }
    }
}

@media screen and (max-width: 1599px) {
    .footer {
        padding: 190px 0 40px;

        &::after {
            border-top-width: 55px;
        }
    }

    .footer__text {
        font-size: 30px;
    }

    .footer__logo {
        padding: 80px 0;
    }
}

@media screen and (max-width: 1199px) {
    .footer {
        padding: 180px 0 30px;

        &::after {
            border-top-width: 45px;
        }
    }

    .footer__text {
        font-size: 25px;
    }

    .footer__logo {
        padding: 60px 0;
    }
}

@media screen and (max-width: 991px) {
    .footer {
        padding: 120px 0 30px;

        &::after {
            border-top-width: 35px;
            top: -1px;
        }
    }

    .footer__text {
        font-size: 22px;
    }

    .footer__logo {
        padding: 50px 0;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        padding: 70px 0 30px;
        overflow: hidden;

        &::after {
            border-top-width: 25px;
            top: 0;
        }
    }

    .footer__text {
        font-size: 20px;
    }

    .footer__logo {
        padding: 22px 0;

        img {
            max-width: 222px;
        }
    }
}

@media screen and (max-width: 575px) {
    .footer__text {
        font-size: 18px;
    }

    .copyright__text {
        &::after {
            display: none;
        }
    }
}
