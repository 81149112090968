.front__intro .container-fluid {
    display: flex;
}

.front__intro .btn {
    margin-bottom: 10px;
}

.front__intro [class*=section__title] {
    margin-bottom: 20px;
    line-height: 1;
}

.front__intro .text__dark {
    display: block;
    max-width: 240px;
}

.front__intro__left {
    flex: 0 0 66%;
    padding-right: 40px;

    p {
        margin-bottom: 35px;
        font-size: 24px;
        line-height: 1.3;

        a {
            text-decoration: underline;

            &:hover {
                color: $secondarycolor;
            }
        }
    }
}

.front__intro__right {
    max-width: 680px;

    .block__links {
        font-size: 24px;

        @media (max-width: 1599px) {
            font-size: 22px;
        }

        @media (max-width: 1199px) {
            font-size: 20px;
        }

        @media (max-width: 991px) {
            font-size: 18px;
        }

        @media (max-width: 767px) {
            font-size: 17px;
        }

        li {
            background-position-y: 9px;
            background-size: 17px;

            @media (max-width: 767px) {
                background-position-y: 4px;
            }
        }
    }

    h3 {
        font-size: 24px;
        line-height: 1.2;
        text-transform: uppercase;

        @media (max-width: 1599px) {
            font-size: 22px;
        }

        strong {
            display: block;
            font-weight: 900;
            color: #000;
        }
    }
}

// Toegevoegde waarden Layer
.toegevoegde {
    display: flex;
    padding-bottom: 0;
}

.toegevoegde__img {
    position: relative;
    z-index: 1;
    width: 66%;
    height: 100%;
    transform: translateY(-80px);

    img {
        max-width: 100%;
        clip-path: url(#toegevoegde-mask);
        padding-bottom: 47px;
        width: 100%;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 14px;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
        background: url(../../svg/lines.svg) no-repeat right top;
        background-size: auto 32.75vw;

        @media (max-width: 1199px) {
            background-size: auto 31vw;
        }
    }
}

.toegevoegde__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: url(#toegevoegde-mask);
}

.toegevoegde__block {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 40px 0 40px 18px;
    background: #def1f0;

    &::before {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 305px;
        height: 100%;
        content: "";
        transform: translateX(-100%);
        pointer-events: none;
        background: $white url(../../svg/toegevoegde-waarden-bg.svg) no-repeat;
        background-size: cover;
        z-index: -1;
    }
}

.toegevoegde__inner {
    max-width: 373px;
}

.toegevoegde__block [class*="section__title"] {
    margin-bottom: 25px;
}

.toegevoegde .block__links {
    font-size: 24px;
}

.home {
    .toegevoegde {
        h3 {
            font-size: 24px;
            line-height: 1.2;
            text-transform: uppercase;

            strong {
                display: block;
                font-weight: 900;
                color: #000;
            }
        }

        .toegevoegde__block {
            top: 50px;

            .block__links {
                margin: 0 0 20px;

                li {
                    background-position-y: 9px;
                    background-size: 17px;
                }
            }
        }
    }
}

// Onze Coating
.onzecoating {
    position: relative;
    z-index: 2;
    overflow: hidden;
    padding-top: 50px;
    padding-left: 20px;

    .slick-track {
        display: flex;
    }

    .slick-slide {
        cursor: pointer;
        height: auto;

        > div {
            height: 100%;
        }
    }
}

.projects__slider,
.layer__slider2 {
    .slick-track {
        display: flex;
    }

    .slick-slide {
        cursor: pointer;
        height: auto;

        > div {
            height: 100%;
        }
    }

    .block {
        height: calc(100% - 40px);
        margin-bottom: 40px;
    }
}

.onzecoating .block__links {
    margin-bottom: 0;
}

.onzecoating .block__summary {
    width: auto;
    margin-top: 0;
}

.onzecoating__item {
    padding-right: 30px;
    height: 100%;

    .block__subtitle {
        text-align: center;
    }
}

.onzecoating .slick-arrow {
    z-index: 1;
    overflow: hidden;
    width: 40px;
    height: 45px;
    opacity: 1;
    background: url(../../svg/arrow-left.svg) no-repeat;
    background-size: contain;

    &::before {
        display: none;
    }
}

.onzecoating__wrapper .slick-next {
    left: -165px;
    display: inherit !important;
}

.onzecoating__wrapper .slick-prev {
    left: -165px;
    display: none !important;
}

.onzecoating .block {
    padding: 0;
    background: $white url(../../svg/block-lines.svg) no-repeat center;
    height: calc(100% - 40px);
    margin-bottom: 40px;

    &:hover {
        .btn {
            opacity: 1;
        }
    }
}

.onzecoating .block__content {
    align-items: center;
    padding: 85px 45px;

    .btn {
        opacity: 0.1;
    }
}

// Projects
.projects {
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-bottom: 50px;
    padding-top: 0;
    padding-left: 20px;

    .layer__slider2 {
        .block__title {
            margin-bottom: 30px;
        }

        .block__content {
            padding-top: 30px;
        }
    }
}

.projects.home-projects .section__title {
    margin-bottom: 60px;
}

.projects__item {
    padding-right: 30px;
    height: 100%;
}

.projects .slick-arrow {
    z-index: 1;
    top: 25%;
    overflow: hidden;
    width: 40px;
    height: 45px;
    opacity: 1;
    background: url(../../svg/arrow-left.svg) no-repeat;
    background-size: contain;

    &::before {
        display: none;
    }
}

.projects .slick-next {
    left: -165px;
    display: inline-block !important;
}

.projects .slick-prev {
    display: none !important;
}

.projects .slick-list {
    padding-top: 10px;
}

.projects .block {
    position: relative;
    overflow: visible;
    padding: 0;
    border: 1px solid transparent;
    background: none;

    img {
        border-radius: 0;
    }

    &:hover {
        border: 1px solid $secondarycolor;
        background-color: #e3f3f2;

        .btn {
            opacity: 1;
        }
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &::before {
        position: absolute;
        z-index: 1;
        right: -2px;
        bottom: -2px;
        left: -2px;
        display: inline-block;
        height: 50%;
        content: "";
        pointer-events: none;
        background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 70%);
    }
}

.projects .block__content {
    align-items: center;
    flex: 1;
    padding: 40px 20px 0;

    .btn {
        opacity: 0.1;
    }
}

.projects .block__title {
    margin-bottom: 0;
    font-size: 28px;
    text-align: center;
}

.projects .block__subtitle {
    margin-bottom: 0;
    text-align: center;
    display: block;
}

.projects .block__readmore {
    margin-top: auto;
}

.projects .block__title__all {
    margin-bottom: 35px;
    display: block;
}

// advies layer
.advies {
    padding: 0 20px;

    p {
        margin: 30px 0 60px;
        font-size: 24px;
        line-height: 1.4;
    }
}

.advies .row {
    align-items: center;
}

.advies [class*="col-"] {
    padding: 0;
}

.advies__content {
    max-width: 550px;
    padding-right: 20px;
}

.advies__img {
    img {
        transform: scale(1.3);
    }
}

// clients
.clients {
    position: relative;

    &::before,
    &::after {
        position: absolute;
        top: -25px;
        right: 0;
        left: 0;
        display: inline-block;
        height: 1px;
        content: "";
        transform: rotate(-2deg);
    }

    &::before {
        background: $primarycolor;
    }

    &::after {
        top: -20px;
        background: rgba($primarycolor, 0.3);
    }
}

.clients .container-fluid {
    display: flex;
    align-items: center;
}

.clients__left {
    width: 280px;
    min-width: 280px;
}

.clients__right {
    flex-grow: 1;
    padding-left: 148px;

    img {
        margin: 10px 0;
    }

    .row {
        align-items: center;
    }

    .partner__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
}

.clients [class*="section__title"] {
    margin-bottom: 0;
    background: -webkit-linear-gradient(135deg, $secondarycolor 50%, $primarycolor 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance:none) {
        .clients [class*="section__title"] {
            -webkit-text-fill-color: initial;
        }
    }
}

.clients [class*="text__dark"] {
    margin-top: 40px;
    -webkit-text-fill-color: initial;
}

@supports (-ms-ime-align:auto) {
    .clients [class*="section__title"] {
        -webkit-text-fill-color: initial;
    }
}

@media (max-width: 1599px) {
    .front__intro .text__dark {
        max-width: 180px;
    }

    .projects__wrapper .slick-prev,
    .projects__wrapper .slick-next {
        left: -10px;
    }

    .onzecoating__wrapper .slick-prev,
    .onzecoating__wrapper .slick-next {
        left: -8px;
    }

    .onzecoating__slider,
    .projects__slider {
        padding-left: 30px;
    }

    .onzecoating .block__content {
        padding: 85px 25px;
    }

    .front__intro__right p {
        margin-bottom: 25px;
        font-size: 22px;
    }

    .toegevoegde .block__links {
        font-size: 22px;
    }

    .toegevoegde__block::before {
        width: 210px;
    }

    .projects .block__title {
        font-size: 22px;
    }

    .projects.home-projects .section__title {
        margin-bottom: 50px;
    }

    .advies p {
        margin: 20px 0 40px;
        font-size: 22px;
    }

    .clients__right {
        padding-left: 120px;
    }

    .projects .block__img__inner,
    .projects .block {
        border-radius: 30px;
    }

    .home .toegevoegde {
        .toegevoegde__block {
            top: 0;
        }

        h3 {
            font-size: 22px;
        }
    }
}

@media (max-width: 1199px) {
    .front__intro .text__dark {
        max-width: 150px;
    }

    .toegevoegde .block__links {
        font-size: 20px;
    }

    .toegevoegde__block::before {
        width: 150px;
    }

    .toegevoegde__img img {
        padding-bottom: 0;
    }

    .projects .block__title {
        font-size: 22px;
    }

    .advies p {
        margin-bottom: 30px;
        font-size: 20px;
    }

    .clients__right {
        padding-left: 90px;
    }

    .projects.home-projects .section__title {
        margin-bottom: 40px;
    }

    .onzecoating .block__content .btn,
    .projects .block__content .btn {
        opacity: 1;
    }

    .onzecoating .block__content {
        padding: 125px 65px;
    }
}

@media (max-width: 991px) {
    .onzecoating__wrapper .slick-prev,
    .onzecoating__wrapper .slick-next,
    .projects__wrapper .slick-prev,
    .projects__wrapper .slick-next {
        left: -5px;
    }

    .onzecoating .slick-arrow {
        width: 30px;
        height: 30px;
        margin-top: -32px;
    }

    .front__intro .text__dark {
        max-width: 100%;
    }

    .onzecoating {
        padding-top: 40px;
    }

    .toegevoegde {
        display: block;
        padding-top: 0;
        padding-right: 20px;
        padding-left: 20px;
    }

    .toegevoegde__inner {
        max-width: 100%;
    }

    .toegevoegde__img {
        overflow: hidden;
        width: 100%;
        margin-bottom: 40px;
        transform: none;
        border-radius: $border-radius;

        img {
            clip-path: initial;
        }

        &::before {
            display: none;
        }
    }

    .toegevoegde__overlay {
        clip-path: initial;
    }

    .toegevoegde__block {
        padding: 40px;
        border-radius: $border-radius;

        &::before {
            display: none;
        }
    }

    .front__intro .container-fluid {
        flex-direction: column;
    }

    .front__intro__left {
        flex: 0;
        padding: 0;
        margin-bottom: 50px;

        @media screen and (max-width: 767px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 575px) {
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
        }
    }

    .front__intro__right {
        max-width: 100%;
    }

    .toegevoegde .block__links {
        font-size: 18px;
    }

    .projects .block__title {
        font-size: 20px;
    }

    .projects.home-projects .section__title {
        margin-bottom: 30px;
    }

    .advies p {
        font-size: 18px;
    }

    .advies__content {
        padding: 20px;
    }

    .clients__right {
        padding-left: 70px;
    }

    .projects .slick-arrow {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 850px) {
    .onzecoating .block__content {
        padding: 65px 25px;
    }
}

@media (max-width: 767px) {
    .onzecoating .block__content {
        padding: 55px 25px;
    }

    .toegevoegde .block__links {
        font-size: 17px;
    }

    .home .toegevoegde .toegevoegde__block .block__links li {
        background-position-y: 4px;
    }

    .projects .block__title {
        font-size: 18px;
    }

    .projects.home-projects .section__title {
        margin-bottom: 20px;
    }

    .advies__img img {
        transform: scale(1);
    }

    .clients .container-fluid {
        align-items: flex-start;
        flex-direction: column;
    }

    .clients__right {
        padding-left: 0;

        .partner__inner {
            justify-content: flex-start;
        }
    }

    .clients [class*=text__dark] {
        margin-top: 20px;
    }

    .clients__left {
        margin: 20px 0;
    }
}

@media (max-width: 575px) {
    .home .onzecoating .slick-arrow,
    .home .projects .slick-arrow {
        width: 40px;
        height: 45px;
        background-color: #0a6b65;
        border-radius: 0 5px 5px 0;
        padding: 10px;
        margin-left: -20px;
        background-size: 12px;
        background-position: center;
    }

    .home .onzecoating__slider,
    .home .projects__slider {
        padding-left: 0;
    }

    .onzecoating__item {
        padding-right: 10px;
    }

    .toegevoegde__img {
        margin-bottom: 30px;
    }

    .clients__right [class*=col-] {
        width: 50%;
    }

    .projects {
        margin-bottom: 20px;
    }

    .clients {
        &::before {
            top: -10px;
        }

        &::after {
            top: -5px;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .toegevoegde__img {
        overflow: hidden;
        width: 62%;
        margin-right: 4%;
        border-radius: 0 $block-radius $block-radius 0;

        &::before {
            display: none;
        }

        img {
            clip-path: initial;
        }
    }

    .toegevoegde__overlay {
        clip-path: initial;
    }

    .toegevoegde__block {
        padding-left: 40px;

        &::before {
            top: 0;
            border-radius: $block-radius 0 0 $block-radius;
            background: #def1f0;
        }
    }
}
